import { Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import GetPublicGalleryHook from './../../CustomHooks/Gallery/GetPublicGalleryHook';

const CategoryDetailsText = () => {

    const { id } = useParams()

    const [getallpublicgallery] = GetPublicGalleryHook()

    return (
        <Container>
            <Row>
{/*                 {
                    getonegallery && getonegallery.data ?

                        (
                            <div>
                                <h2 className='my-4'>{getonegallery.data.data.title}</h2>
                                <p style={{ maxWidth: '90%', lineHeight: '2.1rem', opacity: '0.7', fontWeight: '500', marginLeft: '2rem' }}>
                                   {getonegallery.data.data.description}
                                </p>
                            </div>) : null
                } */}
            </Row>
        </Container>
    )
}

export default CategoryDetailsText
