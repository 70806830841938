import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import AddAndGetReviewsHook from '../../../CustomHooks/Reviews/AddAndGetReviewsHook';
import AddReviewComponent from '../../../Components/Admin/Reviews/AddReviewComponent';
import { Link } from 'react-router-dom';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

const AdminReviewsPage = () => {

    const theme = useTheme()

    //// Temp Array To Store Rows Temporarily
    let temparr = [];

    const [apirows, setrows] = useState([])

    const [, , , , , , , , getallreviews] = AddAndGetReviewsHook()

    const columns = [
        { field: 'col1', headerName: 'ID', width: 32, align: 'center', headerAlign: 'center' },
        { field: 'col2', headerName: 'Name', align: 'center', headerAlign: 'center', flex: 1 },
        { field: 'col3', headerName: 'Rate', align: 'center', headerAlign: 'center' },
        { field: 'col4', headerName: 'Content', align: 'center', headerAlign: 'center', flex: 1 },
        {
            field: 'col5', headerName: 'Operations', align: 'center', headerAlign: 'center',
            renderCell: ({ row: { col5 } }) => {
                return (
                    <Box
                        sx=
                        {{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            width: '99px',
                            verticalAlign: 'middle',
                            textAlign: 'center',
                            padding: '5px',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                            margin: 'auto',
                            marginTop: '10px',
                            color: theme.palette.primary.dark

                        }}>
                        <Typography sx={{ fontSize: '16px' }}>
                            {col5}
                        </Typography>


                    </Box>
                )
            }
        }
    ];

    useEffect(() => {
        if (getallreviews && getallreviews.data) {
            getallreviews.data.data.map((ele, index) => {
                temparr.push({ id: index, col1: ele.id, col2: ele.name, col3: ele.rate, col4: ele.message, col5: <Link to= {`/admin/editreview/${ele.id}`} sx={{color : 'inherit', cursor: 'pointer'}}><ModeEditOutlineOutlinedIcon /></Link> })
            })
        }
        //// Store Temp Array Into Original One 
        setrows(temparr)
    }, [getallreviews])

    //console.log(getallreviews);

    return (
        <div className='fw-bold'>
            <Typography sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>All Reviews</Typography>
            <AddReviewComponent />
            {
                getallreviews && getallreviews.data && getallreviews.data.data ?
                    (
                        <div style={{ height: '100%', width: '100%', overflowY: 'hidden' }} className='my-4'>
                            <DataGrid rows={apirows}
                                // @ts-ignore
                                columns={columns}
                                autoHeight
                                hideFooter={true}                                
                                hideToolbar= {true}
                                getRowId={(row) => row.col1}
                                sx={{ fontWeight: 'bold', fontSize: 16, overflowY: 'hidden' }}
                            />
                        </div>
                    ) : null
            }
        </div>
    )
}

export default AdminReviewsPage
