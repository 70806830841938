import SectionTitle from './../../Components/Utility/SectionTitle';
import { Container, Row } from 'react-bootstrap';
import AboutBanner from './../../Components/About/AboutBanner';
import { Zoom, Roll } from 'react-reveal';
import Footer from './../../Components/Utility/Footer';
import NavBar from './../../Components/Utility/NavBar';
import VerticalBar from '../../Components/Utility/VerticalBar';
import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';


const AboutPage = () => {

    const [getallpublicpagecontent] = GetAllPublicPageContentWithNameHook('About')

    ///console.log(getallpagecontent);

    return (
        <div style={{ minHeight: '650px' }}>

            <VerticalBar />

            <NavBar />


            <Row style={{ minHeight: '400px' }} className='mb-5'>
                <SectionTitle />
            </Row>

            {/*   <Zoom>
                <AboutBanner heading={'Our Start'} imagesrc={aboutBanner1} />
            </Zoom>

            <Fade right>
                <AboutBanner heading={'Our Factory'} imagesrc={aboutBanner2} direction={'reverse'} />
            </Fade>

            <Rotate top left>
                <AboutBanner heading={'Our Designers'} imagesrc={aboutBanner3} />
            </Rotate>

            <Roll left>
                <AboutBanner heading={'We love kitchens'} imagesrc={aboutBanner4} direction={'reverse'} />
            </Roll> */}

            <Container>

                <Row>
                    {
                        getallpublicpagecontent && getallpublicpagecontent.data ?
                            (
                                getallpublicpagecontent.data.data.map((item, index) => {
                                    return (
                                        index % 2 === 0 ?
                                            (
                                                <Zoom>
                                                    <AboutBanner key={index} item={item} />
                                                </Zoom>
                                            )
                                            : (
                                                <Roll>
                                                    <AboutBanner key={index} item={item} direction={'reverse'} />
                                                </Roll>
                                            )
                                    )
                                })
                            ) : null
                    }
                </Row>

            </Container>


            <Footer />

        </div>
    )
}

export default AboutPage
