import {Button, Col, Card } from 'react-bootstrap';

const BrandCard = ({BrandImg}) => {
    return (
            <Col className='mt-4 mb-3' xs='6' md='4' lg='2'>
                <Card className='brandresponsive' style={{
                    width: '350%',
                    height: '100%',
                    border: 'none', 
                    margin: 'auto'
                }}>
                    <Card.Img className='brandlogo' src={BrandImg} />
                </Card>
            </Col>
    )
}

export default BrandCard
