import React from 'react'
import { Col, Row, Container } from 'react-bootstrap';
import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';

const WorkingHours = () => {

    const [getallpublicpagecontent] = GetAllPublicPageContentWithNameHook('Contact')

    return (
        <Container>
            <Row className='g-5 mt-2'>
                {
                    getallpublicpagecontent && getallpublicpagecontent.data ?
                    getallpublicpagecontent.data.data.map((item, index) => {
                            return (
                                <Col key={index} lg='6' xm='12'>
                                    <h2 className='text-danger text-center'>{item.title}</h2>
                                    <p className='text-center mt-4 ps-4' style={{ lineHeight: '40px', opacity: '0.8', fontWeight: '600' }}>
                                        {item.description}
                                    </p>
                                </Col>
                            )
                        }) : null
                }

            </Row>
        </Container>
    )
}

export default WorkingHours
