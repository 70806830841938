import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import GetPublicContactInfoHook from './../../CustomHooks/Contact Info/GetPublicContactInfoHook';

const VerticalBar = () => {

    const [socialshow, setsocialshow] = useState('show')

    const ShowSocialBar = () => {
        if (window.scrollY > (document.body.offsetHeight - 1100)) {
            setsocialshow('')
        }
        else
        {
            setsocialshow('show')
        }
    }

    window.addEventListener("scroll", ShowSocialBar)

    const [getpubliccontactinfo] = GetPublicContactInfoHook()

    //console.log(getpubliccontactinfo);

    return (
        <div>
            {
                getpubliccontactinfo && getpubliccontactinfo.data && getpubliccontactinfo.data.data ?
                    (
                        <div className={`vertical ${socialshow}`}>
                            <ul>
                                <li>
                                    <Link to={getpubliccontactinfo.data.data.facebook} target='_blank'>
                                        <i className="fab fa-facebook-f" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={getpubliccontactinfo.data.data.instagram} target='_blank'>
                                        <i className="fab fa-instagram" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='https://www.tiktok.com/@woody.kitchens.eg' target='_blank'>
                                        <i className="fab fa-tiktok" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={getpubliccontactinfo.data.data.youtube} target='_blank'>
                                        <i className="fab fa-youtube" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to='https://t.me/Woody_Kitchens' target='_blank'>
                                        <i className="fab fa-telegram" />
                                    </Link>
                                </li>
                                <li>
                                    <a href={`tel:${getpubliccontactinfo.data.data.phone}`}>
                                        <i className="fa-solid fa-phone" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    ) : null
            }
        </div>
    )
}

export default VerticalBar
