import CategoryCard from '../Categories/CategoryCard'
import GetPublicGalleryHook from './../../CustomHooks/Gallery/GetPublicGalleryHook';

const HomeGallery = () => {

    const [getallpublicgallery] = GetPublicGalleryHook()

    return (
        <div id="portfolio">
            <div className="container p-0 mb-5">

                <div className="row mt-5 g-0">

                    {
                        getallpublicgallery && getallpublicgallery.data ?
                            (
                                getallpublicgallery.data.data.slice(0, 9).map((item, index) => {
                                    return (<CategoryCard key={index} Gallery={item} />)
                                })
                            ) : null
                    }

                </div>

            </div>
        </div>
    )
}

export default HomeGallery
