import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateAdminPasswordAction } from '../../Redux/Actions/AccountInfoActions';
import notify from './../useNotification';

const UpdateAdminPasswordHook = () => {

    const dispatch = useDispatch();

    const updateadminpw = useSelector(state => state.AccountInfoRed.updateadminpwres)

    const [oldPW, setoldPW] = useState('')
    const [newPW, setnewPW] = useState('')
    const [loadingUpdate, setloadingUpdate] = useState(null)

    const ChangeoldPW = (e) => {
        setoldPW(e.target.value)
    }

    const ChangenewPW = (e) => {
        setnewPW(e.target.value)
    }

    const UpdatePassword = async (e) => {
        e.preventDefault()
        if (oldPW === '' || newPW === '') {
            notify('In Correct Values', 'error')
            return;
        }
        if (newPW.length < 6) {
            notify('New Password Must Be At least 6 Length', 'error')
            return;
        }
        setloadingUpdate(true)
        await dispatch(UpdateAdminPasswordAction({
            "CurrentPassword": oldPW,
            "NewPassword": newPW
        }))
        setloadingUpdate(false)
    }

    useEffect(() => {
        if (loadingUpdate === false) {
            if (updateadminpw.data.message === 'Current password is not valid') {
                notify('Current Password Not Correct', 'warning')
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
            else if(updateadminpw.status === 200 && updateadminpw.data.data === true )
            {
                notify('Changed Successfully, Please Login Again', 'success')
                setTimeout(() => {
                    window.location.pathname = '/login'
                    localStorage.removeItem('UserLoginToken')
                }, 1500);
            }
        }
    }, [loadingUpdate])


    return [oldPW, ChangeoldPW, newPW, ChangenewPW, UpdatePassword]
}


export default UpdateAdminPasswordHook
