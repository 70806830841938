import * as React from 'react';
// import land from '../../../Components/Assets/images/Capture.png'


export default function AdminHomePage() {
    return (
        <div>
            <iframe title='HomeVideo' className='w-100' style={{ minHeight: '90vh' }} src="https://www.youtube.com/embed/wwjTL1A04mE?autoplay=1&mute=1&rel=0&controls=0" allowfullscreen="allowfullscreen"
            ></iframe>
        </div>
    );
}