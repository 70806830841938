import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notify from './../../CustomHooks/useNotification';
import { UpdateContactInfoAction } from '../../Redux/Actions/ContactInfoActions';
import GetContactInfoHook from './GetContactInfoHook';
import { AttachmentsAction } from '../../Redux/Actions/AttachmentsAction';

const UpdateContactInfoHook = () => {

    const [getcontactinfo] = GetContactInfoHook()

    const [loadingImg, setloadingImg] = useState(null)
    const [loadingitem, setloadingitem] = useState(null)


    const [facebookItem, setfacebookItem] = useState('')
    const [instagramItem, setinstagramItem] = useState('')
    const [youtubeItem, setyoutubeItem] = useState('')
    const [linkedinItem, setlinkedinItem] = useState('')
    const [phone, setphone] = useState(0)
    const [logoImg, setlogoImg] = useState('')
    const [logoImgID, setlogoImgID] = useState('')
    const [selectedFile, setselectedFile] = useState(null)
    const myFormData = new FormData()

    const dispatch = useDispatch();

    const createattachment = useSelector(state => state.AttachmentRed.createattachmentres)
    const updatecontactinfo = useSelector(state => state.ContactInfoRed.updatecontactinfores)

    //// Assign Name To Text Box Value 
    useEffect(() => {
        const run = () => {
            if (getcontactinfo) {
                if (getcontactinfo.data) {
                    setfacebookItem(getcontactinfo.data.data.facebook)
                    setinstagramItem(getcontactinfo.data.data.instagram)
                    setyoutubeItem(getcontactinfo.data.data.youtube)
                    setlinkedinItem(getcontactinfo.data.data.linkedin)
                    setphone(getcontactinfo.data.data.phone)
                    setlogoImgID(getcontactinfo.data.data.attachmentId)
                }
            }
        }
        run()
    }, [getcontactinfo])

    ///// On Change Image 
    const onImageChange = async (e) => {
        /// In Case User Selects File (Image)
        /// Change Title Of Image & Image Thumbnail

        if (e.target.files.length > 0) {
            // Assign Image Source to Cat Img

            await setlogoImg(URL.createObjectURL(e.target.files[0]))

            // Assign Span Title To Name Of File 

            await setselectedFile(e.target.files[0])

        }
    }

    const ChangeFacebook = (e) => {
        setfacebookItem(e.target.value)
    }

    const ChangeInstagram = (e) => {
        setinstagramItem(e.target.value)
    }

    const ChangeYouTube = (e) => {
        setyoutubeItem(e.target.value)
    }

    const ChangeLinkedIn = (e) => {
        setlinkedinItem(e.target.value)
    }

    const ChangePhone = (e) => {
        setphone(e.target.value)
    }

    /// Clicking The Button & Update Social
    const updateSocials = async (e) => {

        e.preventDefault();
        if (facebookItem === '' || youtubeItem === '' || instagramItem === '' || linkedinItem === '' || phone == null) {
            notify('يرجي ادخال لينكات تواصل سليمة', 'warning')
            return;
        }
        if (selectedFile === null) {
            updateSocialLinks(logoImgID)
            return;
        }
        setloadingImg(true)
        await myFormData.append("AttachmentType", 1)
        await myFormData.append("File", selectedFile);
        await dispatch(AttachmentsAction(myFormData))
        setloadingImg(false)
    }

    /// On Finishing Upload Image 
    useEffect(() => {
        if (loadingImg === false) {
            // console.log(createattachment);
            if (createattachment.status === 200) {
                if (createattachment.data && createattachment.data.data) {
                    /// Calling Another API To Update All Socials
                    //console.log(createattachment.data.data);
                    updateSocialLinks(createattachment.data.data)
                }
            }

        }
    }, [loadingImg])


    const updateSocialLinks= async(imgID)=> {
        setloadingitem(true)
        await dispatch(UpdateContactInfoAction({
            "Facebook": facebookItem,
            "Instagram": instagramItem,
            "Linkedin": linkedinItem,
            "Youtube": youtubeItem,
            "Phone": phone,
            "AttachmentId": imgID,
            "Id": 1
        }))
        setloadingitem(false)
    }
 

    /// On Finishing Upload Image 
    useEffect(() => {
        if (loadingitem === false) {
            console.log(updatecontactinfo);
            if (updatecontactinfo.status == 200) {
                if (updatecontactinfo.data && updatecontactinfo.data.data) {
                    notify('Updated Successfully', 'success')
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                }
                else {
                    notify('Error In Connection, Please Try Again', 'error')
                }
            }

        }
    }, [loadingitem])


    return [facebookItem, ChangeFacebook,
        instagramItem, ChangeInstagram, youtubeItem, ChangeYouTube,
        linkedinItem, ChangeLinkedIn, phone, ChangePhone, onImageChange,
        updateSocials, logoImg, loadingImg, loadingitem]
}


export default UpdateContactInfoHook

