import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AndroidOutlinedIcon from '@mui/icons-material/AndroidOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import { grey, orange } from '@mui/material/colors';
// import logo from '../Assets/images/Logo.png'


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    // @ts-ignore
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function SideBar({ open, handleDrawerClose }) {

    const loc = useLocation()

    const theme = useTheme();

    const navigate = useNavigate();

    const List1 = [{
        text: 'Home',
        icon: <HomeOutlinedIcon />,
        path: '/admin/home'
    },
    {
        text: 'Contact Messages',
        icon: <GroupOutlinedIcon />,
        path: '/admin/contact'
    },
    {
        text: 'Brands',
        icon: <AndroidOutlinedIcon />,
        path: '/admin/brands'
    },
    {
        text: 'Reviews',
        icon: <ReviewsOutlinedIcon />,
        path: '/admin/reviews'
    },
    {
        text: 'PageContent',
        icon: <ContentCopyOutlinedIcon />,
        path: '/admin/pagecontent'
    },
    {
        text: 'Gallery',
        icon: <DesignServicesOutlinedIcon />,
        path: '/admin/gallery'
    },
    {
        text: 'Social Links',
        icon: <ShareOutlinedIcon />,
        path: '/admin/contactinfo'
    },
    {
        text: 'Account Settings',
        icon: <ManageAccountsOutlinedIcon />,
        path: '/admin/accountinfo'
    },
    {
        text: 'Log Out',
        icon: <LogoutOutlinedIcon />,
        path: '/login'
    }
    ]


    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? < ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            
            <Divider />


            {/* <img sx={{ objectFit: 'contain', mx: 'auto', width: open ? 88 : 44, height: open ? 88 : 44, mt: 5, transition: '0.3s' }} alt="Remy Sharp" src={logo} /> */}
            <Typography sx={{ mb: 1, fontSize: open ? 22 : 0, transition: '0.3s', color: theme.palette.warning.main }} mx='auto'>DashBoard</Typography>



            <Divider />



            <List sx={{ fontFamily: `var(--font-default)` }}>
                {List1.map((element) => (
                    <ListItem key={element.text} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            onClick={() => { navigate(element.path) }}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                py: 1.5,
                                fontWeight: 'bold',
                                bgcolor: element.path === loc.pathname ? theme.palette.mode === 'dark' ? grey[900] : orange[400] : 'none'
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {element.icon}
                            </ListItemIcon>
                            <ListItemText primary={element.text} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>

            <Divider />


        </Drawer>
    )
}
