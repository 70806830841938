import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import GetAllContactMsgHook from '../../../CustomHooks/Contact/GetAllContactMsgHook';
import { useState, useEffect } from 'react';


const AdminContactPage = () => {

    const theme = useTheme()

    //// Temp Array To Store Rows Temporarily
    let temparr = [];

    const [apirows, setrows] = useState([])

    const [getallcontactmsg] = GetAllContactMsgHook()

    const columns = [
        { field: 'col1', headerName: 'ID', width: 32, align: 'center', headerAlign: 'center' },
        { field: 'col2', headerName: 'Name', align: 'center', headerAlign: 'center', flex: 1 },        
        { field: 'col3', headerName: 'Phone', align: 'center', headerAlign: 'center', flex: 1 },
        { field: 'col4', headerName: 'Message', align: 'center', headerAlign: 'center', flex: 1 }
    ];

/*     let rows = [
        { id: 1, col1: '1', col2: 'Jon Snow', col3: 'jonsnow@gmail.com', col4: '35', col5: '(665)121-5454' }
    ];
 */

    useEffect(() => {
        if (getallcontactmsg && getallcontactmsg.data) {
            getallcontactmsg.data.data.map((ele, index) => {
                temparr.push({id: index, col1: ele.id, col2: ele.name, col3: ele.phone, col4: ele.message })
            })
        }
        
        //// Store Temp Array Into Original One 
        setrows(temparr)
    }, [getallcontactmsg])

    // console.log(getallcontactmsg);

    return (
        <div className='fw-bold'>
            <Typography sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>Contact Us Messages</Typography>
            {
                getallcontactmsg && getallcontactmsg.data && getallcontactmsg.data.data ?
                    (
                        <div style={{ height: '100%', }} className='my-4'>
                            <DataGrid rows={apirows}                                
                                // @ts-ignore
                                columns={columns}
                                autoHeight
                                hideFooter={true}                                
                                hideToolbar= {true}
                                sx={{fontWeight: 'bold', fontSize: 16}}
                                 />
                        </div>
                    ) : null
            }
        </div>
    )
}

export default AdminContactPage
