import UpdateGalleryAlbumHook from '../../../../CustomHooks/Gallery/GalleryAlbums/UpdateGalleryAlbumHook';
import Modal from '@mui/material/Modal';
import { Col, Row } from 'react-bootstrap';
import { Box, Button, Typography, Stack, TextField, FormLabel, FormControlLabel, Checkbox } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { MainUrl } from './../../../../Redux/Types';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';
import { useTheme } from '@mui/material/styles';


const AdminEditServiceDetailsItem = () => {

    const { id } = useParams()

    const theme = useTheme()

    const [AlbumTitle, ChangeAlbumTitle, AlbumSubTitle, ChangeAlbumSubTitle, AlbumActiveStatus, ChangeActiveStatus,
        onImageChange, EditAlbum, AlbumImg, AlbumImgCaption, loadingimg, loading] = UpdateGalleryAlbumHook(id)



    return (
        <>

            <Box>
                <Typography sx={{ color: theme.palette.primary.dark }} className='mb-4' id="modal-modal-title" variant="h5" component="h2">
                    Edit Details
                </Typography>
                <Box
                    component="form"
                    sx=
                    {{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Stack direction='row' spacing={2} sx={{ fontSize: '16px' }} >

                        <TextField
                            sx={{ flex: 1 }}
                            label="Main Title"
                            variant="outlined"
                            value={AlbumTitle}
                            onChange={ChangeAlbumTitle}
                        />

                    </Stack>

                    <Row className='d-flex mt-5'>
                        <Col md='1' style={{ alignSelf: 'center' }}>
                            <FormLabel className='fw-bold mt-2'>Image</FormLabel>
                        </Col>
                        <Col md='11'>
                            <label htmlFor='upload'>
                                <img
                                    src={AlbumImg !== '' ? `${MainUrl}/${AlbumImg}` : AlbumImg}
                                    alt=''
                                    style={{ cursor: 'pointer', width: '120px', heigth: '140px', borderRadius: '10px' }}
                                />
                            </label>

                            <input onChange={onImageChange} style={{ opacity: '0', position: 'absolute' }} type='file' id='upload'

                            />

                            <span style={{ margin: 'auto 20px', fontWeight: 'bold' }} dir='rtl'>
                                {AlbumImgCaption}
                            </span>
                        </Col>
                    </Row>



                    {/* <FormControlLabel control={<Checkbox checked={AlbumActiveStatus} onChange={ChangeActiveStatus} />} label="اظهار علي السايت" />  */}


                    {
                        loadingimg || loading ?
                            (
                                <Button disabled onClick={EditAlbum} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>Save Changes</Button>
                            ) : <Button onClick={EditAlbum} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>Save Changes</Button>
                    }

                </Box>

            </Box>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
            />


        </>
    )
}

export default AdminEditServiceDetailsItem
