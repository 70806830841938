import React from 'react'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const SectionTitle = () => {
    return (
        <div className='intpages'>
        </div>
    )
}

export default SectionTitle
