import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttachmentsAction } from '../../../Redux/Actions/AttachmentsAction';
import { GetGalleryItemDetailsAction, UpdateGalleryAlbumAction } from '../../../Redux/Actions/GalleryActions';
import unknown from '../../../Components/Assets/images/unknown.png'
import notify from './../../useNotification';

const UpdateGalleryAlbumHook = (GalleryAlbumID) => {

    const [loadingimg, setloadingimg] = useState(null)
    const [loading, setloading] = useState(null)


    const [AlbumTitle, setAlbumTitle] = useState('')
    const [AlbumSubTitle, setAlbumSubTitle] = useState('')
    const [AlbumActiveStatus, setAlbumActiveStatus] = useState(false)
    const [AlbumAttachID, setAlbumAttachID] = useState('')


    const [AlbumImg, setAlbumImg] = useState(unknown)
    const [AlbumImgCaption, setAlbumImgCaption] = useState('')

    const [selectedFile, setselectedFile] = useState(null)
    const myFormData = new FormData()

    const dispatch = useDispatch();
    const createattachment = useSelector(state => state.AttachmentRed.createattachmentres)

    const getonegallery = useSelector(state => state.GalleryRed.getonegalleryres)

    const updatealbumitem = useSelector(state => state.GalleryRed.updatealbumitemres)

    const ChangeAlbumTitle = (e) => {
        setAlbumTitle(e.target.value)
    }

    const ChangeAlbumSubTitle = (e) => {
        setAlbumSubTitle(e.target.value)
    }

    const ChangeActiveStatus = () => {
        setAlbumActiveStatus(!AlbumActiveStatus)
    }

    // //// On Finishing Start Up Or Create Album
    // useEffect(() => {
    //     const call = async () => {
    //         await dispatch(GetGalleryItemDetailsAction({
    //             "Id": GalleryID
    //         }))
    //     }
    //     call()
    // }, [])

    //// Assign Name To Text Box Value 
    useEffect(() => {
        const run = () => {
            if (getonegallery) {
                if (getonegallery.data) {
                    setAlbumSubTitle(getonegallery.data.data.subTitle)
                    if ((getonegallery.data.data.galleryAlbum) && (getonegallery.data.data.galleryAlbum.length > 0)) {
                        getonegallery.data.data.galleryAlbum.filter((item) => {
                            if (GalleryAlbumID == item.id) {
                                setAlbumImg(item.image)
                                setAlbumTitle(item.title)
                                setAlbumActiveStatus(item.isActive)
                                setAlbumAttachID(item.attachmentId)
                                // console.log(item);
                            }
                        })
                    }
                }
            }
        }
        run()
    }, [getonegallery])

    ///// On Change Image 
    const onImageChange = async (e) => {
        /// In Case User Selects File (Image)
        /// Change Title Of Image & Image Thumbnail

        if (e.target.files.length > 0) {

            // Assign Image Source 

            await setAlbumImg(URL.createObjectURL(e.target.files[0]))

            // Assign Span Title To Cat Name 
            await setAlbumImgCaption(e.target.files[0].name)

            await setselectedFile(e.target.files[0])
        }
        else {
            notify('Image Should Be Valid', 'warning')
        }

    }



    /// Clicking The Button & Save Image To Data Base 
    const EditAlbum = async (e) => {

        e.preventDefault();
        if (AlbumTitle === '' || AlbumSubTitle === '') {
            notify('Please Enter Valid Input Values', 'warning')
            return;
        }
        if (selectedFile === null && AlbumAttachID == '') {
            notify('InValid Image', 'warning')
            return;
        }
        if (selectedFile === null && AlbumAttachID != '') {
            UpdateAlbumItem(AlbumAttachID)
            return;
        }
        setloadingimg(true)
        await myFormData.append("AttachmentType", 1)
        await myFormData.append("File", selectedFile);
        await dispatch(AttachmentsAction(myFormData))
        setloadingimg(false)
    }

    /// On Finishing Upload Image 
    useEffect(() => {
        const call = async () => {
            if (loadingimg === false) {
                //console.log(createattachment);
                if (createattachment.status === 200) {
                    if (createattachment.data && createattachment.data.data) {
                        /// Calling Another API To Create One Album
                        await UpdateAlbumItem(createattachment.data.data)
                    }
                }
            }
        }
        call()
    }, [loadingimg])

    //// Update Album Item Function With ImageID Parameter
    const UpdateAlbumItem = async (ImgID) => {
        setloading(true)
        await dispatch(UpdateGalleryAlbumAction({
            "Id": GalleryAlbumID,
            "AttachmentId": ImgID,
            "Title": AlbumTitle,
            "SubTitle": AlbumSubTitle,
            "IsActive": AlbumActiveStatus
        }))
        setloading(false)
    }

    //// On Finishing Create Album
    useEffect(() => {

        const Run = async () => {
            if (loading === false) {
                //console.log(updatealbumitem);
                if (updatealbumitem.status === 200) {
                    setAlbumTitle('')
                    setAlbumSubTitle('')
                    setAlbumImg(unknown)
                    setAlbumImgCaption('')
                    notify('Updated Successfully', 'success')
                    setTimeout(() => {
                        window.location.pathname = `/admin/gallery`
                    }, 1500);
                }
                else if (updatealbumitem.status === 401) {
                    notify("Please Login Again To Complete", 'error')
                }
                else {
                    notify("Error In Connection, Please Try Again Later", 'error')
                }
            }
        }
        Run()

    }, [loading])

    return [AlbumTitle, ChangeAlbumTitle, AlbumSubTitle, ChangeAlbumSubTitle, AlbumActiveStatus, ChangeActiveStatus,
        onImageChange, EditAlbum, AlbumImg, AlbumImgCaption, loadingimg,
        loading, getonegallery]
}


export default UpdateGalleryAlbumHook
