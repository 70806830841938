import { Link } from 'react-router-dom';
import { MainUrl } from '../../Redux/Types';
import cat2 from '../../Assets/images/Categories/Cat2.jpg'


const CategoryCard = ({ Gallery }) => {

    
    return (
        <div style={{ cursor: 'Pointer' }} className="col-lg-4 col-sm-6">
            <Link to={`/categorydetails/${Gallery.id}`}>
                <div className="portfolio-box" title="Project Name">
                    <img style={{height: '370px'}} className="img-fluid" src={Gallery.galleryAlbum.length > 0 ? `${MainUrl}/${Gallery.galleryAlbum[0].image}` : cat2} alt="..." />
                    <div className="portfolio-box-caption">
                        <div className="project-category text-white-50">{Gallery.title}</div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default CategoryCard
