import { Col, Row, Container } from 'react-bootstrap';
import React from "react";
import { MainUrl } from '../../Redux/Types';

const BlogComp = ({ item }) => {
    return (
        <Container>
            <Row className={'ps-3 d-flex justify-content-center align-items-center'}>
                <Col lg='6' xm='12' className='mt-5 mb-1 ps-4'>
                    <h2 style={{ color: '#CD5C5C', textAlign: 'center' }}>
                        {item.title}
                    </h2>
                    <h5 style={{ color: '#738063' }} className='mt-3 text-center'>
                        {item.subTitle}
                    </h5>
                    <p style={{ lineHeight: '1.9rem', marginTop: '2rem', fontWeight: '800', width: '90%', opacity: '0.95' }}>
                        {item.description}
                    </p>
                </Col>


                <Col lg='6' xm='12'>
                    <div className='form mt-5 mx-4'>
                        <img style={{borderRadius: '50%', objectFit: 'cover', maxWidth: '95%', minHeight: '300px', marginTop: '2rem' }} src={`${MainUrl}/${item.image}`} alt='' />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default BlogComp
