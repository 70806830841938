import { Col, Row, Container } from 'react-bootstrap';
import React from "react";
import { MainUrl } from './../../Redux/Types';

const AboutBanner = ({ item, direction }) => {
    return (
        <Container>
            <Row className={`ps-3 mb-5 ${direction}`}>
                <Col lg='6' xm='12' className='mt-5 ps-4'>
                    <h2 style={{ color: '#CD5C5C', textAlign: 'center' }}>
                        {item.title}
                    </h2>
                    <h5 style={{ color: '#738063' }} className='mt-3 text-center'>
                        {item.subTitle}
                    </h5>
                    <p className='fs-5' style={{ lineHeight: '2.3rem', marginTop: '2rem', fontWeight: '800' ,fontStyle: 'italic' }}>
                        {item.description}
                    </p>
                </Col>


                <Col lg='6' xm='12'>
                    <div className='form mt-3'>
                        <img style={{ objectFit: 'cover', maxWidth: '95%', minHeight: '300px', marginTop: '2rem' }} src={`${MainUrl}/${item.image}`} alt='' />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AboutBanner
