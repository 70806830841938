import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetOneBrandDetailsAction, UpdateBrandAction } from '../../Redux/Actions/BrandsAction';
import notify from './../../CustomHooks/useNotification';
import {AttachmentsAction} from './../../Redux/Actions/AttachmentsAction';


const EditBrandHook = (BID) => {

    const dispatch = useDispatch();

    const [loadingimg, setloadingimg] = useState(null)
    const [loading, setloading] = useState(null)

    
    const [brandImg, setbrandImg] = useState('')
    const [brandName, setbrandName] = useState('')
    const [brandImgCaption, setbrandImgCaption] = useState('')
    const [selectedFile, setselectedFile] = useState(null)
    const [BrandIsActive, setBrandIsActive] = useState(false)

    const myFormData = new FormData()

    let getonebrand = useSelector(state => state.BrandRed.onebrandres)
    const createattachment = useSelector(state => state.AttachmentRed.createattachmentres)
    let updatebrand = useSelector(state => state.BrandRed.updatebrandres)

    /// Handle Get Details of Brand Action With ID Parameter  
    useEffect(() => {
        const run = async () => {
            await dispatch(GetOneBrandDetailsAction({
                "id": BID
            }))
        }
        run()
    }, [])

    //// Assign Name To Text Box Value 
    useEffect(() => {
        const run = () => {
            if (getonebrand) {
                if (getonebrand.data) {
                    setbrandName(getonebrand.data.data.name)
                    setBrandIsActive(getonebrand.data.data.isActive)
                }
            }
        }
        run()
    }, [getonebrand])


    //// Change The Input Value 
    const changeBrandName = (e) => {
        setbrandName(e.target.value)
    }

    const ChangeActive = (e) => {
        setBrandIsActive(!BrandIsActive)
    }

    ///// On Change Image 
    const onImageChange = async (e) => {
        /// In Case User Selects File (Image)
        /// Change Title Of Image & Image Thumbnail

        if (e.target.files.length > 0) {
            // Assign Image Source to Cat Img

            await setbrandImg(URL.createObjectURL(e.target.files[0]))

            // Assign Span Title To Cat Name 
            await setbrandImgCaption(e.target.files[0].name)

            await setselectedFile(e.target.files[0])

        }
    }


        /// Convert URL To File Object
/* 
    const convertUrlToFile = async (url) => {

        const response = await fetch(url, { mode: 'cors' });
        const data = await response.blob();
        const ext = url.split(".").pop();
        const filename = url.split("/").pop()
        const metadata = { type: `image/jpeg` }
        return new File([data], Math.random(), metadata)

    } */


    /// Clicking The Button & Save Image To Data Base 
    const updateBrand = async (e) => {

        e.preventDefault();
        // if (brandName === '' || brandName.length < 2) {
        //     notify('Title Should Be Valid', 'warning')
        //     return;
        // }
        if (selectedFile === null) {
            updateOneBrand(getonebrand.data.data.attachmentId)
            return;
        }
        setloadingimg(true)
        await myFormData.append("AttachmentType", 1)
        await myFormData.append("File", selectedFile);
        await dispatch(AttachmentsAction(myFormData))
        setloadingimg(false)
    }

    /// On Finishing Upload Image 
    useEffect(() => {
        if (loadingimg === false) {
            //console.log(createattachment);
            if (createattachment.status === 200) {
                if (createattachment.data && createattachment.data.data) {
                    /// Calling Another API To Create One Brand
                    updateOneBrand(createattachment.data.data)
                }
            }
        }
    }, [loadingimg])

    //// Edit Brand Function With ImageID Parameter
    const updateOneBrand = async (ImgID) => {
        setloading(true)
        await dispatch(UpdateBrandAction({
            "attachmentId": ImgID,
            "id": BID,
            "isActive": BrandIsActive,
            "name": brandName
        }))
        setloading(false)
    }

    //// On Finishing Edit Brand
    useEffect(() => {
        const Run = async () => {
            if (loading === false) {
                //console.log(updatebrand);
                    setbrandName('')
                setbrandImgCaption('')
                notify('Brand Updated Successfully', 'success')
                setTimeout(() => {
                    window.location.pathname = "/admin/brands"
                }, 1000);
            }
        }

        Run()

    }, [loading])


    return [getonebrand, brandName, changeBrandName, onImageChange, updateBrand, brandImg, brandImgCaption, loadingimg,
        loading, BrandIsActive, ChangeActive]
}


export default EditBrandHook
