import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePage from './Pages/UI Pages/HomePage';
import ContactPage from './Pages/UI Pages/ContactPage';
import AboutPage from './Pages/UI Pages/AboutPage';
import LoginPage from './Pages/Authentication/LoginPage';
import GalleryPage from './Pages/UI Pages/GalleryPage';
import QuotationPage from './Pages/UI Pages/QuotationPage';
import CategoryDetails from './Pages/UI Pages/CategoryDetailsPage';
import ProtectedRoute from './Components/Utility/ProtectedRoute';
import ProtectedRouteHook from './CustomHooks/Authentication/ProtectedRouteHook';

import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import AdminPageParent from './Pages/Admin/AdminPageParent';
import AdminHomePage from './Pages/Admin/Home/AdminHomePage';
import AdminContactPage from './Pages/Admin/ContactUs/AdminContactPage';
import AdminBrandsPage from './Pages/Admin/Brands/AdminBrandsPage';
import AdminEditBrandPage from './Pages/Admin/Brands/AdminEditBrandPage';
import ContactInfoPage from './Pages/Admin/ContactInfo/ContactInfoPage';
import AdminReviewsPage from './Pages/Admin/Reviews/AdminReviewsPage';
import AdminEditReviewPage from './Pages/Admin/Reviews/AdminEditReviewPage';
import PContentContainerPage from './Pages/Admin/PageContent/PContentContainerPage';
import EditPContentPage from './Pages/Admin/PageContent/EditPContentPage';
import AddPContentPage from './Pages/Admin/PageContent/AddPContentPage';
import AccountInfoPage from './Pages/Admin/AccountInfo/AccountInfoPage';
import AdminServicesPage from './Pages/Admin/Services/AdminServicesPage';
import AdminEditServicePage from './Pages/Admin/Services/AdminEditServicePage';
import AdminServiceDetailsPage from './Pages/Admin/Services/ServiceDetails/AdminServiceDetailsPage';
import AdminEditSDetailsItemPage from './Pages/Admin/Services/ServiceDetails/AdminEditSDetailsItemPage';
import BlogsPage from './Pages/UI Pages/BlogsPage';


function App() {

  const [userData, isAdmin] = ProtectedRouteHook()

  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/categories' element={<GalleryPage />} />
          <Route path='/categorydetails/:id' element={<CategoryDetails />} />
          <Route path='/quote' element={<QuotationPage />} />
          <Route path='/blogs' element={<BlogsPage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/login' element={<LoginPage />} />

          <Route element={<ProtectedRoute PermissionFlag={isAdmin} />}>

            <Route element={<AdminPageParent />}>


              <Route path='/admin/home' element={<AdminHomePage />} />

              <Route path="/admin/contact" element={<AdminContactPage />} />

              <Route path='/admin/brands' element={<AdminBrandsPage />} />

              <Route path='/admin/editbrand/:id' element={<AdminEditBrandPage />} />

              <Route path='/admin/contactinfo' element={<ContactInfoPage />} />


              <Route path='/admin/reviews' element={<AdminReviewsPage />} />

              <Route path='/admin/editreview/:id' element={<AdminEditReviewPage />} />

              <Route path="/admin/pagecontent" element={<PContentContainerPage />} />
              <Route path="/admin/editpagecontent/:id" element={<EditPContentPage />} />
              <Route path="/admin/addpagecontent/:name" element={<AddPContentPage />} />



              <Route path="/admin/accountinfo" element={<AccountInfoPage />} />


              <Route path="/admin/gallery" element={<AdminServicesPage />} />
              <Route path="/admin/editserviceitem/:id" element={<AdminEditServicePage />} />
              <Route path="/admin/getservicedetails/:id" element={<AdminServiceDetailsPage />} />
              <Route path="/admin/editservicedetails/:id" element={<AdminEditSDetailsItemPage />} />



            </Route>

          </Route>

        </Routes>
      </BrowserRouter>

      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Flip}
      />

    </div >
  )
}

export default App;
