import ContactForm from '../../Components/Contact/ContactForm';
import { Row } from 'react-bootstrap';
import SectionTitle from './../../Components/Utility/SectionTitle';
import WorkingHours from './../../Components/Contact/WorkingHours';
import Footer from './../../Components/Utility/Footer';
import NavBar from './../../Components/Utility/NavBar';
import VerticalBar from '../../Components/Utility/VerticalBar';


const ContactPage = () => {
    return (
        <div>

            <NavBar />

            <VerticalBar />

            <Row style={{ minHeight: '400px' }}>
                <SectionTitle />
            </Row>

            <WorkingHours />

            <div>
                <ContactForm />
            </div>

            <Footer />


        </div>
    )
}

export default ContactPage
