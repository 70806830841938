import { Row, Col } from 'react-bootstrap';
import SectionTitle from '../../Components/Utility/SectionTitle';
import CatImageGallery from '../../Components/Image Gallery/ImageGallery';
import CategoryDetailsText from './../../Components/Categories/CategoryDetailsText';
import Footer from './../../Components/Utility/Footer';
import NavBar from './../../Components/Utility/NavBar';
import VerticalBar from '../../Components/Utility/VerticalBar';


const CategoryDetails = () => {
    return (
        <div style={{ minHeight: '650px' }}>
            <NavBar />

            <VerticalBar />

            <Row style={{ minHeight: '400px' }}>
                <SectionTitle />
            </Row>

            <div className="mt-5 d-flex justify-content-center text-center m-auto">
                <Col xs='8'>
                    <CatImageGallery />
                </Col>
            </div>

            <CategoryDetailsText />

            <Footer />

        </div>
    )
}

export default CategoryDetails
