import React from "react";
import GetAllPublicReviewsHook from './../../CustomHooks/Reviews/GetAllPublicReviewsHook';
import {
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";

const HomeReviews = () => {

  const [getallpublicreviews] = GetAllPublicReviewsHook()

  //console.log(getallpublicreviews);

  function DisplayRate(max) {
    let items = []
    if (max > 5) {
      max = 5
    }
    for (let i = 0; i < max; i++) {
      items.push(
        <li key={i}>
          <MDBIcon fas icon="star" size="sm" className="text-warning" />
        </li>
      );
    }

    return <>{items}</>;
  }

  return (
    <MDBContainer className="py-3">
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="10" xl="8" className="text-center">
          <h3 className="mb-4" style={{ color: '#CD5C5C' }}>Reviews</h3>
          <p className="mb-4 pb-2 mb-md-5 pb-md-0 fw-bold fs-5" style={{color: '#738063'}}>
            What Our Clients Say ...
          </p>
        </MDBCol>
      </MDBRow>
      <MDBRow className="text-center">

        {
          getallpublicreviews && getallpublicreviews.data ?
            (
              getallpublicreviews.data.data.slice(0, 3).map((rev, index) => {
                return (
                  <MDBCol md="4" className="mb-5 mb-md-0" key={index}>
                    <div className="d-flex justify-content-center mb-4">
                      <img
                        src={`https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(${index + 1}).webp`}
                        className="rounded-circle shadow-1-strong"
                        width="150"
                        height="150"
                      />
                    </div>
                    <h5 className="mb-3" style={{color: '#CD5C5C'}}>{rev.name}</h5>
                    <p className="px-xl-3 fw-bold">
                      <MDBIcon fas icon="quote-left" className="pe-2" />
                      {rev.message}
                    </p>
                    <MDBTypography
                      listUnStyled
                      className="d-flex justify-content-center mb-0"
                    >
                      {
                        DisplayRate(rev.rate)
                      }

                    </MDBTypography>
                  </MDBCol>
                )
              })
            ) : null
        }

      </MDBRow>
    </MDBContainer>
  );
}

export default HomeReviews
