import React from 'react';
import { useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import GetPublicContactInfoHook from './../../CustomHooks/Contact Info/GetPublicContactInfoHook';
import { MainUrl } from './../../Redux/Types';
import { NavLink } from "react-router-dom";


const NavBar = () => {

    /// Handling Scrolling & Add Overlay To Nav Bar 

    const [fix, setfix] = useState(false)

    //console.log(window.screen.width);

    const ChangeNavBackground = () => {
        // if (window.location.pathname === '/') {
        //     if ((window.scrollY > 150) || (window.screen.width < 992)) {
        //         setfix(true)
        //     }
        //     else {
        //         setfix(false)
        //     }
        // }
        // else {
        //     setfix(true)
        // }
    }

    window.addEventListener("load", ChangeNavBackground)
    window.addEventListener("scroll", ChangeNavBackground)
    window.addEventListener("resize", ChangeNavBackground)

    const [getpubliccontactinfo] = GetPublicContactInfoHook()



    return (
        <Navbar className={fix ? 'navbar down fixed-top' : 'navbar down fixed-top'} expand="lg" variant='light'>
            <Container>
                {

                    getpubliccontactinfo && getpubliccontactinfo.data && getpubliccontactinfo.data.data ?
                        <Navbar.Brand style={{ flexBasis: '45%', zIndex: '9999', padding: '0.8rem' }}>
                            <a href='/'>

                                <img src={`${MainUrl}/${getpubliccontactinfo.data.data.logo}`} alt="" className='imglogo' />

                            </a>
                        </Navbar.Brand> : null
                }
                <Navbar.Toggle className='toggle' aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>                        
                        <NavLink to='/' className='menu-item me-3 d-flex justify-content-center align-items-center'>
                            <p>Home</p>
                        </NavLink>
                        <NavLink to='/about' className='menu-item me-3 d-flex justify-content-center align-items-center'>
                            <p>About</p>
                        </NavLink>
                        <NavLink to='/categories' className='menu-item me-3 d-flex justify-content-center align-items-center'>
                            <p>Gallery</p>
                        </NavLink>
                        <NavLink to='/quote' className='menu-item me-3 d-flex justify-content-center align-items-center'>
                            <p>Get Quotation</p>
                        </NavLink>
                        <NavLink to='/blogs' className='menu-item me-3 d-flex justify-content-center align-items-center'>
                            <p>Blogs</p>
                        </NavLink>
                        <NavLink to='/contact' className='menu-item me-3 d-flex justify-content-center align-items-center'>
                            <p>Contact US</p>
                        </NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar