import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttachmentsAction } from '../../../Redux/Actions/AttachmentsAction';
import { CreateAlbumItemAction, GetGalleryItemDetailsAction } from '../../../Redux/Actions/GalleryActions';
import unknown from '../../../Components/Assets/images/unknown.png'
import notify from './../../useNotification';

const AddAndGetGalleryAlbumHook = (GalleryID) => {

    const [loadingimg, setloadingimg] = useState(null)
    const [loading, setloading] = useState(null)


    const [AlbumTitle, setAlbumTitle] = useState('')
    const [AlbumSubTitle, setAlbumSubTitle] = useState('')
    const [AlbumImg, setAlbumImg] = useState(unknown)
    const [AlbumImgCaption, setAlbumImgCaption] = useState('')

    const [selectedFile, setselectedFile] = useState(null)
    const myFormData = new FormData()

    const dispatch = useDispatch();
    const createattachment = useSelector(state => state.AttachmentRed.createattachmentres)

    const getonegallery = useSelector(state => state.GalleryRed.getonegalleryres)

    const createalbumitem = useSelector(state => state.GalleryRed.createalbumitemres)

    const ChangeAlbumTitle = (e) => {
        setAlbumTitle(e.target.value)
    }

    const ChangeAlbumSubTitle = (e) => {
        setAlbumSubTitle(e.target.value)
    }

    ///// On Change Image 
    const onImageChange = async (e) => {
        /// In Case User Selects File (Image)
        /// Change Title Of Image & Image Thumbnail

        if (e.target.files.length > 0) {
            //console.log(URL.createObjectURL(e.target.files[0]))

            // Assign Image Source 

            await setAlbumImg(URL.createObjectURL(e.target.files[0]))

            // Assign Span Title To Cat Name 
            await setAlbumImgCaption(e.target.files[0].name)

            await setselectedFile(e.target.files[0])
        }
        else {
            notify('InValid Image', 'warning')
        }

    }


    /// Clicking The Button & Save Image To Data Base 
    const addAlbum = async (e) => {

        e.preventDefault();
        if (AlbumTitle === '' || AlbumSubTitle === '') {
            notify('Please Enter Valid Input Values', 'warning')
            return;
        }
        if (selectedFile === null) {
            notify('Please Enter Valid Input Values', 'warning')
            return;
        }
        setloadingimg(true)
        await myFormData.append("AttachmentType", 1)
        await myFormData.append("File", selectedFile);
        await dispatch(AttachmentsAction(myFormData))
        setloadingimg(false)
    }

     //// On Finishing Start Up Or Create Album
        useEffect(() => {
            const call = async () => {
                await dispatch(GetGalleryItemDetailsAction({
                    "Id": GalleryID
                }))
            }
            call()
        }, []) 

    /// On Finishing Upload Image 
    useEffect(() => {
        const call = async () => {
            if (loadingimg === false) {
                // console.log(createattachment);
                if (createattachment.status === 200) {
                    if (createattachment.data && createattachment.data.data) {
                        /// Calling Another API To Create One Album
                        await CreateAlbumItem(createattachment.data.data)
                    }
                }
                else
                {
                    notify('Not Supported Extension', 'error')
                }
            }
        }
        call()
    }, [loadingimg])

    //// Create Album Item Function With ImageID Parameter
    const CreateAlbumItem = async (ImgID) => {
        setloading(true)
        await dispatch(CreateAlbumItemAction({
            "GalleryId": GalleryID,
            "AttachmentId": ImgID,
            "Title": AlbumTitle,
            "SubTitle": AlbumSubTitle
        }))
        setloading(false)
    }

    //// On Finishing Create Album
    useEffect(() => {

        const Run = async () => {
            if (loading === false) {
                // console.log(createalbumitem);
                if (createalbumitem && createalbumitem.status === 200) {
                    setAlbumTitle('')
                    setAlbumSubTitle('')
                    setAlbumImg(unknown)
                    setAlbumImgCaption('')
                    notify('Details Added Successfully', 'success')
                    // setTimeout(() => {
                    //     window.location.reload()
                    // }, 2500);
                }
                else if (createalbumitem && createalbumitem.status === 401)
                {
                    notify("Please Login Again To Complete", 'error')
                }
                else
                {
                    notify("Error In Connection , Please Try Again Later", 'error')
                }
            }
            dispatch(GetGalleryItemDetailsAction({
                "Id": GalleryID
            }))
        }
        Run()

    }, [loading])

    return [AlbumTitle, ChangeAlbumTitle, AlbumSubTitle, ChangeAlbumSubTitle,
        onImageChange, addAlbum, AlbumImg, AlbumImgCaption, loadingimg,
        loading, getonegallery]
}


export default AddAndGetGalleryAlbumHook
