import SectionTitle from './../../Components/Utility/SectionTitle';
import { Row } from 'react-bootstrap';
import Footer from './../../Components/Utility/Footer';
import NavBar from './../../Components/Utility/NavBar';
import VerticalBar from '../../Components/Utility/VerticalBar';
import GetPublicGalleryHook from './../../CustomHooks/Gallery/GetPublicGalleryHook';
import CategoryCard from './../../Components/Categories/CategoryCard';

const GalleryPage = () => {

    const [getallpublicgallery] = GetPublicGalleryHook()

    //console.log(getallpublicgallery);

    return (
        <div style={{ minHeight: '650px' }}>
            <NavBar />

            <VerticalBar />

            <Row style={{ minHeight: '400px' }}>
                <SectionTitle />
            </Row>

            <Row>
                <h2 style={{color: '#CD5C5C'}} className='text-center mt-5'>Participate Our Success</h2>
            </Row>

            <div id="portfolio">
                <div className="container p-0 mb-5">

                    <div className="row mt-5 g-0">


                        {
                            getallpublicgallery && getallpublicgallery.data ?
                                (
                                    getallpublicgallery.data.data.map((item, index) => {
                                        return (<CategoryCard key={index} Gallery={item} />)
                                    })
                                ) : null
                        }

                    </div>

                </div>

            </div>

            <Footer />

        </div>
    )
}

export default GalleryPage
