import BrandCard from './BrandCard';
import { Row, Container } from 'react-bootstrap';
import { MainUrl } from '../../Redux/Types';
import Carousel from 'react-grid-carousel'
import GetAllPublicBrandsHook from './../../CustomHooks/Brands/GetAllPublicBrandsHook';

const HomeBrands = () => {

    const [getallpublicbrands] = GetAllPublicBrandsHook()

    // console.log(getallpublicbrands);

    return (
        <Container>
            <Row>
                <h3 className='mb-4 mt-5 text-center' style={{ color: '#CD5C5C' }}>
                    Proud To Work With
                </h3>
            </Row>
            <div className="container-fluid">
                <Carousel cols={6} rows={1} gap={2} loop autoplay={3500}>
                    {
                        getallpublicbrands && getallpublicbrands.data ?
                            (
                                getallpublicbrands.data.data.map((item, index) => {
                                    return (
                                        <Carousel.Item key={index}>

                                            <BrandCard BrandImg={`${MainUrl}/${item.image}`} />
                                        </Carousel.Item>

                                    )
                                })
                            ) : null
                    }

                </Carousel>

            </div>

        </Container>
    )
}

export default HomeBrands;