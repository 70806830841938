import { Button, Col, Row, Container } from 'react-bootstrap';
import React from "react";
import CreateMessageHook from './../../CustomHooks/Contact/CreateMessageHook';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';


const ContactForm = () => {

    const [Name, changeName, Phone, changePhone, Message, changeMessage, postmessage] = CreateMessageHook()

    return (
        <Container>
            <Row className='contactus g-5'>
                <Col lg='12' xm='12' className='form'>
                    <h3>Get In Touch</h3>
                    <input
                        value={Name}
                        onChange={changeName}
                        className='form-control fw-bold' type="text" placeholder="Your Name"></input>
                    <input
                        value={Phone}
                        onChange={changePhone}
                        className='form-control fw-bold' type="number" placeholder="Your Phone"></input>
                    <textarea
                        value={Message}
                        onChange={changeMessage}
                        className='form-control fw-bold' name="" id="" cols="30" rows="10" placeholder="Your Message"></textarea>
                    <Button
                    onClick={postmessage}
                    style={{backgroundColor: '#738063'}}
                     className="primbtn d-block mt-2 mb-4 m-auto">Submit</Button>
                </Col>

                <Col lg='12' xm='12'>
                    <div>
                        <iframe
                            title='Woody Location'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6909.214416889994!2d31.41128233411559!3d30.01943304938807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583ceff38ac8f7%3A0x603bacbdea4310fd!2z2K_Yp9mI2YYg2KrYp9mI2YYg2KfZhNmC2LfYp9mF2YrYqQ!5e0!3m2!1sar!2seg!4v1724878825748!5m2!1sar!2seg"                            
                            height="450"
                            width="100%"
                            style={{ border: '0' }}>
                        </iframe>
                    </div>
                </Col>
            </Row>

            <ToastContainer
                position="top-left"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
            />
        </Container>
    )
}



export default ContactForm
