import React from 'react'
import HomeVideos from '../../Components/Home/HomeVideos';
import HomeReviews from './../../Components/Home/HomeReviews';
import HomeBrands from './../../Components/Home/HomeBrands';
import AboutBanner from './../../Components/About/AboutBanner';
import HomeGallery from './../../Components/Home/HomeGallery';
import Hero from './../../Components/Home/Hero';
import { Row } from 'react-bootstrap';
import Footer from './../../Components/Utility/Footer';
import NavBar from './../../Components/Utility/NavBar';
import VerticalBar from '../../Components/Utility/VerticalBar';
import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';


const HomePage = () => {

    const [getallpublicpagecontent] = GetAllPublicPageContentWithNameHook('')

    return (
        <div style={{ minHeight: '650px' }}>

            <VerticalBar />

            <NavBar />

            <Hero getallpublicpagecontent = {getallpublicpagecontent} />

            <div className='mt-5'>
                {
                    getallpublicpagecontent && getallpublicpagecontent.data ?
                        (
                            getallpublicpagecontent.data.data.map((item, index) => {
                                if (item.pageName === 'About' && item.id === 3) {
                                    return (
                                        <AboutBanner key={index} item={item} />
                                    )
                                }
                            })
                        ) : null
                }


            </div>
            <Row>
                <h3 style={{ color: '#CD5C5C' }} className='mb-2 text-center'>
                    Woody Gallery
                </h3>
            </Row>
            <HomeGallery />
            <HomeReviews />
            <HomeVideos />
            <HomeBrands />


            <Footer />

        </div>
    )
}

export default HomePage
