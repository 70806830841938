import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import LoginHook from './../../CustomHooks/Authentication/LoginHook';

const LoginPage = () => {

    const [email, password, loading, changeemail, changepassword, loginsubmit] = LoginHook()

    return (
        <div className="loginPage">
            <div className="LoginContainer">
                <div className="screen">
                    <div className="screen__content">
                        <form className="login">
                            <div className="login__field">
                                <i className="login__icon fas fa-user" />
                                <input onChange={changeemail} value={email} type="text" className="login__input" placeholder="Admin Email" />
                            </div>
                            <div className="login__field">
                                <i className="login__icon fas fa-lock" />
                                <input onChange={changepassword} value={password} type="password" className="login__input" placeholder="Admin Password" />
                            </div>
                            <button className="button login__submit" onClick={loginsubmit}>
                                <span className="button__text">Log In Now</span>
                                <i className="button__icon fas fa-chevron-right" />
                            </button>
                        </form>
                    </div>
                    <div className="screen__background">
                        <span className="screen__background__shape screen__background__shape4" />
                        <span className="screen__background__shape screen__background__shape3" />
                        <span className="screen__background__shape screen__background__shape2" />
                        <span className="screen__background__shape screen__background__shape1" />
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-left"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition= {Flip}
            />

        </div>
    )
}

export default LoginPage
