import SectionTitle from '../../Components/Utility/SectionTitle';
import { Row, Container } from 'react-bootstrap';
import Footer from './../../Components/Utility/Footer';
import NavBar from './../../Components/Utility/NavBar';
import VerticalBar from '../../Components/Utility/VerticalBar';
import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';
import BlogComp from './../../Components/Blogs/BlogComp';



const BlogsPage = () => {

    const [getallpublicpagecontent] = GetAllPublicPageContentWithNameHook('blogs')

    return (
        <div style={{ minHeight: '950px' }}>
            <NavBar />

            <VerticalBar />

            <Row style={{ minHeight: '400px' }}>
                <SectionTitle />
            </Row>

            <Container>

                <Row>
                    {
                        getallpublicpagecontent && getallpublicpagecontent.data ?
                            (
                                getallpublicpagecontent.data.data.map((item, index) => {
                                    return (
                                        <BlogComp key={index} item={item} />
                                    )
                                })
                            ) : null
                    }
                </Row>

            </Container>

            <Footer />

        </div>
    )
}

export default BlogsPage
