import { Container, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AdminBrandCard from './AdminBrandCard';
import AddBrandHook from './../../../CustomHooks/Brands/AddBrandHook';
import AddBrandItem from './AddBrandItem';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';

const AdminBrandsContainer = () => {

    const [, , , , , , , , allactivebrands] = AddBrandHook();

    const theme = useTheme()

    return (
        <div>
            <Typography className='mb-4' sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>Brands</Typography>
            <AddBrandItem />
            <Container>
                <Stack style={{ flexWrap: 'wrap', justifyContent: 'center', gap: '30px' }} direction={'row'}>
                    {
                        allactivebrands && allactivebrands.data ?
                            allactivebrands.data.data.map((item, index) => {
                                return (<AdminBrandCard key={index} brand={item} />)
                            }) : null
                    }
                </Stack>
            </Container>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Flip}
            />
        </div>
    )
}

export default AdminBrandsContainer
