import { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getDesignTokens } from '../../theme';
import { Outlet } from 'react-router-dom';
import TopBar from '../../Components/Admin/TopBar';
import SideBar from '../../Components/Admin/SideBar';
// import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const cacheRtl = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer],
});



export default function AdminPageParent() {
    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    /// Initial State For Theme Mode
    const [mode, setMode] = useState(Boolean(localStorage.getItem('CurrentMode')) ? localStorage.getItem('CurrentMode') : 'dark');

    // Update the theme only if the mode changes
    const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <Box>
                    <CssBaseline />

                    <TopBar open={open} handleDrawerOpen={handleDrawerOpen} setMode={setMode} />

                    <SideBar open={open} handleDrawerClose={handleDrawerClose} />


                    <Box className={open ? 'openchild' : 'closechild' } component="main" sx={{ flexGrow: 1, p: 3, fontWeight: 'bold', ml: open ? '240px' : '60px' }}>
                        <DrawerHeader />

                        <Outlet />

                    </Box>
                </Box>
            </ThemeProvider>
        </CacheProvider> 
    );
}