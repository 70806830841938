import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateBrandAction, GetActiveBrandsAction } from '../../Redux/Actions/BrandsAction';
import up from './../../Components/Assets/images/UploadBrand.jpg'
import { AttachmentsAction } from './../../Redux/Actions/AttachmentsAction';
import notify from './../useNotification';

const AddBrandHook = () => {

    const [loadingimg, setloadingimg] = useState(null)
    const [loading, setloading] = useState(null)

    const [brandName, setbrandName] = useState('')
    const [brandImg, setbrandImg] = useState(up)
    const [brandImgCaption, setbrandImgCaption] = useState('')
    const [selectedFile, setselectedFile] = useState(null)
    const myFormData = new FormData()

    const dispatch = useDispatch();
    const createBrands = useSelector(state => state.BrandRed.createbrandres)
    const createattachment = useSelector(state => state.AttachmentRed.createattachmentres)
    const allactivebrands = useSelector(state => state.BrandRed.allactivebrandsres)

    const changeBrandName = (e) => {
        setbrandName(e.target.value)
    }

    ///// On Change Image 
    const onImageChange = async (e) => {
        /// In Case User Selects File (Image)
        /// Change Title Of Image & Image Thumbnail

        if (e.target.files.length > 0) {
            //console.log(URL.createObjectURL(e.target.files[0]))

            // Assign Image Source to Cat Img

            await setbrandImg(URL.createObjectURL(e.target.files[0]))

            // Assign Span Title To Brand Name 
            await setbrandImgCaption(e.target.files[0].name)

            await setselectedFile(e.target.files[0])
        }
        else {
            notify('Image Should Be Valid', 'warning')
        }

    }


    /// Clicking The Button & Save Image To Data Base 
    const addBrand = async (e) => {

        e.preventDefault();
        /*         if (brandName === '' || brandName.length < 2) {
                    notify('Title Should Be Valid', 'warning')
                    return;
                } */
        if (selectedFile === null) {
            notify('Image Should Be Valid', 'warning')
            return;
        }
        setloadingimg(true)
        await myFormData.append("AttachmentType", 1)
        await myFormData.append("File", selectedFile);
        await dispatch(AttachmentsAction(myFormData))
        setloadingimg(false)

    }

    //// On Finishing Start Up Or Create Brand
    useEffect(() => {
        const call = async () => {
            await dispatch(GetActiveBrandsAction({
                "isActiveOnly": false
            }))
        }
        call()
    }, [])

    /// On Finishing Upload Image 
    useEffect(() => {
        if (loadingimg === false) {
            //console.log(createattachment);
            if (createattachment.status === 200) {
                if (createattachment.data && createattachment.data.data) {
                    /// Calling Another API To Create One Brand
                    CreateOneBrand(createattachment.data.data)
                }
            }
            else {
                notify('UnSupported File', 'error')
            }
        }
    }, [loadingimg])

    //// Create Brand Function With ImageID Parameter
    const CreateOneBrand = async (ImgID) => {
        setloading(true)
        await dispatch(CreateBrandAction({
            "name": 'Brand' + Math.random(),
            "attachmentId": ImgID
        }))
        setloading(false)
    }

    //// On Finishing Create Brand
    useEffect(() => {

        const Run = async () => {
            if (loading === false) {
                if (createBrands && createBrands.data && createBrands.data.data) {
                    if (createBrands.status === 200) {
                        // console.log(createBrands);
                        setbrandName('')
                        document.getElementById("outlined-image-input").value = "";
                        setselectedFile(null)
                        notify('Brand Added Successfully', 'success')
                    }
                }
            }
    }

        Run()

    }, [loading])

return [brandName, changeBrandName, onImageChange, addBrand, brandImg, brandImgCaption, loadingimg,
    loading, allactivebrands, selectedFile]
}


export default AddBrandHook
