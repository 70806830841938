import { Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';

const Hero = ({ getallpublicpagecontent }) => {

    return (
        <div>
            {/* Home */}
            {
                getallpublicpagecontent && getallpublicpagecontent.data ?
                    (
                        getallpublicpagecontent.data.data.map((item, index) => {
                            if (item.pageName === 'Home') {
                                return (<section key={index} className="home pt-3 pb-5 d-flex align-items-center" id="header">
                                    <div className='container text-light py-5'>


                                        <h1 style={{ lineHeight: '4rem', marginTop: '10rem', color: '#f44336', fontFamily: "Pacifico, cursive", fontWeight: "400", fontStyle: 'italic' }} className="headline"><span className="home_text d-inline-block fw-bold" style={{
                                            fontStyle: 'italic', fontFamily: "Pacifico, cursive", fontWeight: "400", fontStyle: 'italic'
                                        }}>{item.title}</span><br />{item.subTitle}</h1>
                                        <p style={{ lineHeight: '2.5rem', fontSize: '22px' }} className="mt-1 para py-4">{item.description}

                                        </p>



                                        <Link to='/quote'>
                                            <Button style={{backgroundColor: '#738063'}} className="primbtn mt-2 mx-5 mb-4">Book Now</Button>
                                        </Link>

                                    </div>


                                </section>
                                )
                            }
                        })
                    ) : null
            }
        </div>
    )
}

export default Hero
