import SectionTitle from '../../Components/Utility/SectionTitle';
import { Row } from 'react-bootstrap';
import QuotationText from '../../Components/Quotation/QuotationText';
import Footer from './../../Components/Utility/Footer';
import NavBar from './../../Components/Utility/NavBar';
import VerticalBar from '../../Components/Utility/VerticalBar';



const QuotationPage = () => {
    return (
        <div style={{ minHeight: '950px' }}>
            <NavBar />

            <VerticalBar />

            <Row style={{ minHeight: '400px' }}>
                <SectionTitle />
            </Row>

            <QuotationText />

            <Footer />

        </div>
    )
}

export default QuotationPage
