import React from 'react'
import logo from '../../Assets/images/Logo.png'
import GetPublicContactInfoHook from './../../CustomHooks/Contact Info/GetPublicContactInfoHook';
import { MainUrl } from './../../Redux/Types';

const Footer = () => {

    const [getpubliccontactinfo] = GetPublicContactInfoHook()

    // console.log(getpubliccontactinfo);

    return (
        <div>
            {/* Footer */}
            <section className="footer text-light mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 py-2 py-md-5">
                            <div className="d-flex align-items-center">
                                {
                                    getpubliccontactinfo && getpubliccontactinfo.data && getpubliccontactinfo.data.data ?
                                        (
                                            <a href='/'>

                                                <img src={`${MainUrl}/${getpubliccontactinfo.data.data.logo}`} alt="" className='imglogo' />

                                            </a>
                                        ) : null
                                }
                            </div>
                            <p className="py-1 fs-5 mt-4" style={{ lineHeight: '3rem' }}>We are waiting for you. Step by and have a hot coffee with our designers and you will be definitely satisfied, whatever you wish we have something for you.
                            </p>
                        </div> {/* end of col */}
                        <div className="col-lg-4 col-sm-6 py-2 py-md-5" style={{ textAlign: 'center' }}>
                            <div>
                                <h4 className="py-3" style={{ color: '#EAE6E3 ' }}>Quick Links</h4>
                                <div className="d-flex align-items-center py-1">
                                    <a style={{ textDecoration: 'none', margin: 'auto' }} href="/about"><p style={{ color: '#FFF' }} className="ms-3 fs-5">About <b className='home_text'>WOODY</b></p></a>
                                </div>
                                <div className="d-flex align-items-center py-1">
                                    <a style={{ textDecoration: 'none', margin: 'auto' }} href="/quote"><p style={{ color: '#FFF' }} className="ms-3 fs-5">Get Quote</p></a>
                                </div>
                                <div className="d-flex align-items-center py-1">
                                    <a style={{ textDecoration: 'none', margin: 'auto' }} href="/categories"><p style={{ color: '#FFF' }} className="ms-3  fs-5">Gallery</p></a>
                                </div>
                                <div className="d-flex align-items-center py-1">
                                    <a style={{ textDecoration: 'none', margin: 'auto' }} href="/categories"><p style={{ color: '#FFF' }} className="ms-3  fs-5">Blogs</p></a>
                                </div>
                                <div className="d-flex align-items-center py-1">
                                    <a style={{ textDecoration: 'none', margin: 'auto' }} href="/contact"><p style={{ color: '#FFF' }} className="ms-3 fs-5">Contact</p></a>
                                </div>
                            </div>
                        </div> {/* end of col */}
                        <div className="col-lg-4 col-sm-6 py-4 py-md-5" style={{ textAlign: 'center' }}>
                            <div>
                                <h4 className="py-3" style={{ color: '#EAE6E3' }}>Contact Info</h4>
                                <div className="d-flex align-items-center py-1">
                                    {
                                        getpubliccontactinfo && getpubliccontactinfo.data && getpubliccontactinfo.data.data ?
                                            (
                                                <a style={{ textDecoration: 'none', margin: 'auto' }} href={`tel:${getpubliccontactinfo.data.data.phone}`}><p className='fs-5' style={{color: '#fff'}}>{getpubliccontactinfo.data.data.phone}</p></a>
                                            ) : null
                                    }
                                </div>
                                <div className="d-flex align-items-center py-1">
                                    <a style={{ textDecoration: 'none', margin: 'auto' }}><p className='fs-5 ms-3' style={{ color: '#FFF' }}>Woody@gmail.com</p></a>
                                </div>

                                {
                                    getpubliccontactinfo && getpubliccontactinfo.data && getpubliccontactinfo.data.data ?
                                        (
                                            <div className="rounded-social-buttons mt-3">
                                                <a className="social-button facebook" href={getpubliccontactinfo.data.data.facebook} target="_blank"><i className="fab fa-facebook-f"></i></a>
                                                <a className="social-button instagram" href={getpubliccontactinfo.data.data.instagram} target="_blank"><i className="fab fa-instagram"></i></a>
                                                <a className="social-button linkedin" href="https://t.me/Woody_Kitchens" target="_blank"><i className="fab fa-telegram"></i></a>
                                                <a className="social-button tiktok" href="https://www.tiktok.com/@woody.kitchens.eg" target="_blank"><i className="fab fa-tiktok"></i></a>
                                                <a className="social-button youtube" href={getpubliccontactinfo.data.data.youtube} target="_blank"><i className="fab fa-youtube"></i></a>                                                
                                            </div>
                                        ) : null
                                }

                            </div>
                        </div> {/* end of col */}
                    </div> {/* end of row */}
                </div> {/* end of container */}
            </section> {/* end of footer */}
            {/* Bottom */}
            <div className="bottom py-2 text-light">
                <div className="container d-flex justify-content-between">
                    <div className='text-center m-auto fs-5'>
                        All Rights Reserved To WOODY&copy;2024 , Made By <span style={{ color: '#0ABFC8' }}>Our Team</span>
                    </div>
                </div> {/* end of container */}
            </div> {/* end of bottom */}
        </div>
    )
}

export default Footer
