import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import GetPublicGalleryHook from './../../CustomHooks/Gallery/GetPublicGalleryHook';

const HomeVideos = () => {

    const [getallpublicgallery] = GetPublicGalleryHook()

    return (
        <div className='mt-5'>
            <Container>
                <Row>
                    <h3 className='mb-5 text-center' style={{ color: '#CD5C5C' }}>
                        Sample Of Our Work
                    </h3>
                </Row>
                <Row>

                    {
                        getallpublicgallery && getallpublicgallery.data ?
                            (
                                getallpublicgallery.data.data.slice(0, 2).map((item, index) => {
                                    if (item.videoLink.length > 0) {
                                        return (
                                            <Col key={index} xm='12' md='6'>

                                                <iframe className='w-100' style={{ minHeight: '40vh' }} src={item.videoLink} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                                            </Col>
                                        )
                                    }
                                })
                            ) : null
                    }

                </Row>
            </Container>
        </div>
    )
}

export default HomeVideos
