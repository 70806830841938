import React from 'react'
import AddAndGetGalleryAlbumHook from './../../../../CustomHooks/Gallery/GalleryAlbums/AddAndGetGalleryAlbumHook';
import { useParams } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import AdminServiceDetailsCard from './AdminServiceDetailsCard';
import { useTheme } from '@mui/material/styles';
import { Stack, Box, TextField, Button, Typography, FormLabel } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';


const AdminServiceDetails = () => {

    const { id } = useParams()

    const [AlbumTitle, ChangeAlbumTitle, AlbumSubTitle, ChangeAlbumSubTitle,
        onImageChange, addAlbum, AlbumImg, AlbumImgCaption, loadingimg,
        loading, getonegallery] = AddAndGetGalleryAlbumHook(id)

    const theme = useTheme()

    // console.log(getonegallery);

    return (
        <div>
            <Typography className='mb-4' sx={{ color: theme.palette.primary.dark, fontWeight: 'bold', fontSize: '25px' }}>Gallery Details</Typography>

            <Accordion variant='outlined' sx={{ fontFamily: `var(--font-default)` }}>
                <AccordionSummary
                    expandIcon={<AddCircleOutlineOutlinedIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    {
                        getonegallery && getonegallery.data ?
                            (
                                <Typography sx={{ color: theme.palette.primary.dark, fontFamily: `var(--font-default)`, fontWeight: 'bold' }}>Add Gallery Item Details : {getonegallery.data.data.title}</Typography>
                            ) : <Typography sx={{ color: theme.palette.primary.dark, fontFamily: `var(--font-default)`, fontWeight: 'bold' }}>Add Gallery Item Details</Typography>
                    }

                </AccordionSummary>
                <AccordionDetails>
                    <Box
                        component="form"
                        sx=
                        {{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4,
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Stack direction='row' spacing={2} sx={{ fontSize: '16px' }} >

                            <TextField
                                sx={{ flex: 1 }}
                                label="Main Title"
                                inputProps={{ style: { minHeight: '3rem' } }}
                                variant="outlined"
                                value={AlbumTitle}
                                onChange={ChangeAlbumTitle}
                            />

                            <TextField
                                sx={{ flex: 1 }}
                                label="Sub Title"
                                inputProps={{ style: { minHeight: '3rem' } }}
                                variant="outlined"
                                value={AlbumSubTitle}
                                onChange={ChangeAlbumSubTitle}
                            />

                        </Stack>

                        <Stack sx={{flexDirection: 'Row', alignItems: 'center'}}>

                        
                            <FormLabel className='fw-bold mx-4 mt-2'>Image</FormLabel>
                        
                        
                            {
                                getonegallery && getonegallery.data ?
                                    <label htmlFor='upload'>
                                        <img
                                            src={AlbumImg}
                                            alt=''
                                            style={{ cursor: 'pointer', width: '120px', heigth: '140px', borderRadius: '10px' }}
                                        />
                                    </label>
                                    : null
                            }
                            <input style={{ opacity: '0', position: 'absolute', cursor: 'pointer' }} type='file' id='upload'
                                onChange={onImageChange}
                            />
                            <span style={{ margin: 'auto 20px', fontWeight: 'bold' }} dir='rtl'>
                                {AlbumImgCaption}
                            </span>

                            </Stack>

                        {
                            loadingimg || loading ?
                                (
                                    <Button className='mt-2 mb-3' disabled onClick={addAlbum} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>Add Details</Button>
                                ) : <Button className='mt-2 mb-3' onClick={addAlbum} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px', display: 'block' }} type="" variant='contained'>Add Details</Button>
                        }


                    </Box>

                </AccordionDetails>
            </Accordion>


            <Row className='g-4 d-flex my-5'>
                {
                    getonegallery && getonegallery.data ?
                        getonegallery.data.data.galleryAlbum.length > 0 ?
                            (
                                getonegallery.data.data.galleryAlbum.map((item, index) => {
                                    return <AdminServiceDetailsCard key={index} ServiceAlbum={item} />
                                })
                            ) : <Typography className='mb-4' sx={{ color: theme.palette.secondary.dark, fontWeight: 'bold', fontSize: '18px' }}>لا يوجد تفاصيل</Typography>
                        : null
                }
            </Row>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Slide}
            />

        </div>
    )
}

export default AdminServiceDetails
