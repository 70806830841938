import { Col } from "react-bootstrap"
import { Link } from 'react-router-dom';
import { MainUrl } from './../../../Redux/Types';
import { useTheme } from '@mui/material/styles';


const AdminBrandCard = ({ brand }) => {

    const theme = useTheme()

    return (

        <Col>
            <div  className='my-3' style={{ fontFamily: `var(--font-default)`, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img alt='' style={{ height: '80px', objectFit: 'cover', width: '80px' }} src={`${MainUrl}/${brand.image}`} />

                <Link className='mt-3' to={`/admin/editbrand/${brand.id}`} style={{ color: theme.palette.primary.dark, fontWeight: 'bold', cursor: 'pointer' }}>Edit
                </Link>
            </div>
        </Col>
    )
}

export default AdminBrandCard
