import { Typography, Box, TextField, Stack, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useForm } from "react-hook-form"
import { useState } from 'react';
import AddAndGetReviewsHook from './../../../CustomHooks/Reviews/AddAndGetReviewsHook';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';


const AddReviewComponent = () => {

    const [ReviewName, ChangeReviewName, ReviewRate, ChangeReviewRate,
        ReviewMessage, ChangeReviewMsg, addReviewItem, loadingReview, getallreviews] = AddAndGetReviewsHook()

    const theme = useTheme();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()


    const onSubmit = () => {
        /* console.log('Done');*/
        /*handleClick();*/

    }

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <div className='my-5'>

            <Box
                onSubmit={handleSubmit(onSubmit)}
                component="form"
                sx=
                {{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                }}
                noValidate
                autoComplete="off"
            >
                <Stack direction='row' spacing={2} sx={{ fontSize: '16px' }} >

                    <TextField
                        inputProps={{ style: { padding: '1.5rem' } }}
                        sx={{ flex: 1 }}
                        label="Reviewer Name"
                        variant="outlined"
                        error={Boolean(errors.name)}
                        {...register("name", { required: true, minLength: 3 })}
                        helperText={Boolean(errors.name) === true ? 'Mandatory, Min Length 3 Chars' : null}
                        value={ReviewName}
                        onChange={ChangeReviewName}
                    />


                    <TextField
                        sx={{ flex: 1 }}
                        inputProps={{ style: { padding: '1.5rem' } }}
                        label="Reviewer Rate"
                        type='number'
                        variant="outlined"
                        error={Boolean(errors.rate)}
                        {...register("rate", { required: true, min: 0, max: 5 })}
                        helperText={Boolean(errors.rate) === true ? 'Allowed Value Between 1 & 5' : null}
                        value={ReviewRate}
                        onChange={ChangeReviewRate}
                    />
                </Stack>


                <TextField
                    multiline
                    id="filled-basic"
                    inputProps={{ style: { padding: '1.5rem' } }}
                    label="Review Content"
                    variant="outlined"
                    error={Boolean(errors.message)}
                    {...register("message", { required: true, minLength: 5 })}
                    helperText={Boolean(errors.message) === true ? 'Mandatory, Min Length 5 Chars' : null}
                    value={ReviewMessage}
                    onChange={ChangeReviewMsg}
                />

                <Box sx={{ textAlign: 'center', marginTop: 3, textTransform: 'capitalize' }}>
                    <Button onClick={addReviewItem} sx={{ backgroundColor: theme.palette.primary.dark, color: '#fff', fontWeight: 'bold', fontSize: '18px' }} type="" variant='contained'>Add New Item</Button>
                </Box>

            </Box>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Flip}
            />

        </div>
    )
}

export default AddReviewComponent
