import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import notify from './../../CustomHooks/useNotification';
import { GetPageContentActionByID, UpdatePageContentAction } from '../../Redux/Actions/PageContentActions';
import { AttachmentsAction, CreatePageContentAttachAction, UpdatePageContentAttachAction } from '../../Redux/Actions/AttachmentsAction';

const EditPageContentHook = (SectionID) => {

    const [loadingimg, setloadingimg] = useState(null)
    const [loadingpagesection, setloadingpagesection] = useState(null)
    const [loadingitem, setloadingitem] = useState(null)
    const [loadingcreatebind, setloadingcreatebind] = useState(null)


    const [pagename, setpagename] = useState('')
    const [pagetitle, setpagetitle] = useState('')
    const [pagesubtitle, setpagesubtitle] = useState('')
    const [pagedesc, setpagedesc] = useState('')
    const [pagestatus, setpagestatus] = useState(true)
    const [pageimg, setpageimg] = useState('')
    const [pageImgCaption, setpageImgCaption] = useState('')
    const [selectedFile, setselectedFile] = useState(null)
    const [attachID, setattachID] = useState(0)


    const dispatch = useDispatch();
    const myFormData = new FormData()
    const getpagecontentwithid = useSelector(state => state.PageContentRed.getpagecontentwithidres)

    const updatebindsectionwithimage = useSelector(state => state.AttachmentRed.updatebindsectionwithimageres)
    const bindsectionwithimage = useSelector(state => state.AttachmentRed.bindsectionwithimageres)

    const updatepagecontent = useSelector(state => state.PageContentRed.updatepagecontentres)
    const createattachment = useSelector(state => state.AttachmentRed.createattachmentres)



    /// Handle Get Details of Page Content Section With ID Parameter  
    useEffect(() => {
        const run = async () => {
            await dispatch(GetPageContentActionByID({
                "Id": SectionID
            }))
        }
        run()
    }, [])

    //// Assign Name To Text Box Value 
    useEffect(() => {
        const run = () => {
            if (getpagecontentwithid) {
                if (getpagecontentwithid.data) {
                    setpagename(getpagecontentwithid.data.data.pageName)
                    setpagetitle(getpagecontentwithid.data.data.title)
                    setpagesubtitle(getpagecontentwithid.data.data.subTitle)
                    setpagedesc(getpagecontentwithid.data.data.description)
                    setpagestatus(getpagecontentwithid.data.data.isActive)
                    setattachID(getpagecontentwithid.data.data.attachmentId)
                }
            }
        }
        run()
    }, [getpagecontentwithid])

    // console.log(getpagecontentwithid);

    const changePageTitle = (e) => {
        setpagetitle(e.target.value)
    }

    const changePageSubTitle = (e) => {
        setpagesubtitle(e.target.value)
    }

    const changePageDesc = (e) => {
        setpagedesc(e.target.value)
    }

    const changePageStatus = (e) => {
        setpagestatus(!pagestatus)
    }

    ///// On Change Image 
    const onImageChange = async (e) => {
        /// In Case User Selects File (Image)
        /// Change Title Of Image & Image Thumbnail

        if (e.target.files.length > 0) {
            // Assign Image Source to Cat Img

            await setpageimg(URL.createObjectURL(e.target.files[0]))

            // Assign Span Title To Cat Name 
            await setpageImgCaption(e.target.files[0].name)

            await setselectedFile(e.target.files[0])

        }
    }

    /// Clicking The Button & Update Item
    const updateSection = async (e) => {

        e.preventDefault();
        if (pagetitle === '' || pagetitle.length < 2) {
            notify('In Valid Main Title', 'warning')
            return;
        }
        if (pagesubtitle === '') {
            notify('In Valid Sub Title', 'warning')
            return;
        }
        if (pagedesc === '') {
            notify('In Valid Description', 'warning')
            return;
        }
        if (selectedFile === null && attachID == 0) {
            //// In Case Of Not Existent Image 
            notify('Please Select Valid Image', 'warning')
            // console.log(attachID);
            return;
        }
        if (selectedFile === null && attachID != 0) {
            //// In Case Of Existent Image Without Change & Modifying Text Only 
            await UpdatePageSectionWithChangeImageOrNot();
            return;
        }

        //// In Case Of Change Image & Modifying Text 
        setloadingimg(true)
        await myFormData.append("AttachmentType", 1)
        await myFormData.append("File", selectedFile);
        await dispatch(AttachmentsAction(myFormData))
        setloadingimg(false)
    }

    /// On Finishing Upload Image 
    useEffect(() => {
        if (loadingimg === false) {
            // console.log(createattachment);
            if (createattachment.status === 200) {
                if (createattachment.data && createattachment.data.data) {
                    /// Calling Another API To Create One Page Section
                    setattachID(createattachment.data.data)
                }
            }
        }
    }, [loadingimg])


    // Calling API For Update Page Section

    const UpdatePageSectionWithChangeImageOrNot = async () => {
        setloadingpagesection(true)
        await dispatch(UpdatePageContentAction({
            "PageName": pagename,
            "Title": pagetitle,
            "SubTitle": pagesubtitle,
            "Description": pagedesc,
            "Id": SectionID,
            "IsActive": pagestatus
        }))
        setloadingpagesection(false)
    }

    useEffect(() => {
        if (loadingimg === false) {
            //// Update Page Section With Modified Text 
            UpdatePageSectionWithChangeImageOrNot()
        }
    }, [attachID])


    //// On Finishing Updating Page Section 
    useEffect(() => {
        const Run = async () => {
            if (loadingpagesection === false) {
                // console.log(updatepagecontent);
                if (updatepagecontent.status === 200) {
                    if (updatepagecontent.data && updatepagecontent.data.data) {
                        /// Last Step : Binding Updated Section ID With Uploaded Image ID
                        BindSectionToImage(SectionID, attachID);
                    }
                }
            }
        }
        Run()
    }, [loadingpagesection])



    /// On Finishing Upload Image 
    useEffect(() => {
        if (loadingitem === false) {
            // console.log(updatebindsectionwithimage);
            if (updatebindsectionwithimage.status === 200) {
                if (updatebindsectionwithimage.data && updatebindsectionwithimage.data.data) {
                    setpagetitle('')
                    setpagesubtitle('')
                    setpagedesc('')
                    setpagestatus(false)
                    setpageimg('image')
                    setpageImgCaption('')
                    setselectedFile(null)
                    notify('Updated Successfully', 'success')
                    setTimeout(() => {
                        window.location.pathname = "/admin/pagecontent"
                    }, 2000);
                }
                else if (updatebindsectionwithimage.data.requestStatus === 500) {
                    CreateBindSectionToImage(SectionID, attachID)                    
                }
            }
            else {
                notify('Please Login Again To Complete', 'error')
            }

        }
    }, [loadingitem])


    useEffect(() => {
        if (loadingcreatebind === false) {
            console.log(bindsectionwithimage);
            if (bindsectionwithimage.status === 200) {
                if (bindsectionwithimage.data && bindsectionwithimage.data.data) {
                    setpagetitle('')
                    setpagesubtitle('')
                    setpagedesc('')
                    setpagestatus(false)
                    setpageimg('image')
                    setpageImgCaption('')
                    setselectedFile(null)
                    notify('Updated Successfully', 'success')
                    setTimeout(() => {
                        window.location.pathname = "/admin/pagecontent"
                    }, 2000);
                }                
            }
            else {
                notify('Please Login Again To Complete', 'error')
            }

        }
    }, [loadingcreatebind])


    //// Update Bind Page Section ID With Already Present AttachmentID
    const BindSectionToImage = async (secID, attachmentsID) => {
        setloadingitem(true)
        await dispatch(UpdatePageContentAttachAction({
            "PageContentId": secID,
            "AttachmentId": attachmentsID
        }))
        setloadingitem(false)
    }



    //// Create Bind Page Section ID With New AttachmentID
    const CreateBindSectionToImage = async (secID, attachmentsID) => {
        setloadingcreatebind(true)        
        await dispatch(CreatePageContentAttachAction({
            "PageContentId": secID,
            "AttachmentId": attachmentsID
        }))        
        setloadingcreatebind(false)
    }



    return [getpagecontentwithid, pagename, pagetitle, changePageTitle, pagesubtitle, changePageSubTitle,
        pagedesc, changePageDesc, pagestatus, changePageStatus, pageimg, pageImgCaption, onImageChange,
        updateSection, loadingimg, loadingpagesection, loadingitem, loadingcreatebind]
}


export default EditPageContentHook

