import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import RightArrow from './RightArrow';
import LeftArrow from './LeftArrow';
import cat2 from '../../Assets/images/AboutBanner2.jpg'
import cat3 from '../../Assets/images/AboutBanner3.jpg'
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MainUrl } from './../../Redux/Types';
import GetPublicGalleryHook from './../../CustomHooks/Gallery/GetPublicGalleryHook';
import { Container, Row } from 'react-bootstrap';


const CatImageGallery = () => {

    const { id } = useParams()

    // const [getonegallery] = EditGalleryItemHook(id)

    const [getallpublicgallery] = GetPublicGalleryHook()

    let [images, setImages] = useState([])
    let [title, settitle] = useState('')
    let [desc, setdesc] = useState('')

    //console.log(getonegallery);
    /* 
        const images = [
            {
                original: cat2,
                thumbnail: cat2
            },
            {
                original: cat3,
                thumbnail: cat3
            },
        ];
     */

    let tempArray = []

    useEffect(() => {
        const call = async () => {
            if (getallpublicgallery && getallpublicgallery.data) {

                getallpublicgallery.data.data.map((item) => {
                    if (item.id == id) {
                        settitle(item.title)
                        setdesc(item.description)
                        /// In Case Of Images
                        if (item.galleryAlbum.length > 0) {
                            console.log('true');
                            item.galleryAlbum.map((ele) => {
                                tempArray.push({
                                    original: `${MainUrl}/${ele.image}`,
                                    thumbnail: `${MainUrl}/${ele.image}`
                                })
                            })
                        }
                        else {
                            /// No Images Returned
                            tempArray.push(
                                { original: cat2, thumbnail: cat2 },
                                { original: cat3, thumbnail: cat3 })
                        }
                    }
                })
            }
            return setImages(tempArray)
        }
        call()
    }, [getallpublicgallery])


    return (
        <div>
            <div className='text-center m-auto'>
                <ImageGallery

                    items={images}

                    originalWidth={false}

                    showPlayButton={true}

                    showFullscreenButton={true}

                    thumbnailPosition='left'

                    disableThumbnailScroll={false}

                    autoPlay={true}

                    showNav={false}

                    slideInterval='1500'

                    renderRightNav={RightArrow}

                    renderLeftNav={LeftArrow}
                />

            </div>

            <Row className='mt-5 text-start'>

                {
                    title || desc ?
                        (

                            <Row>
                                <h2 style={{color: '#CD5C5C'}} className='my-4'>{title}</h2>
                                <p style={{ lineHeight: '2.3rem', opacity: '0.7', fontWeight: '800', marginLeft: '', fontFamily: 'tahoma' }}>
                                    {desc}
                                </p>
                            </Row>

                        ) : null
                }

            </Row>

        </div>
    )
}

export default CatImageGallery
