import AboutBanner from './../About/AboutBanner';
import ContactBanner from '../../Assets/images/ContactBanner.jpg'
import { Link } from 'react-router-dom'
import { Button, Row } from 'react-bootstrap';
import GetAllPublicPageContentWithNameHook from './../../CustomHooks/PageContent/GetAllPublicPageContentWithNameHook';

const QuotationText = () => {

    const [getallpublicpagecontent] = GetAllPublicPageContentWithNameHook('Quotation')


    return (
        <div className='px-5 mb-5'>
            <div className='mt-5'>
                <Row>
                    {

                        getallpublicpagecontent && getallpublicpagecontent.data ?
                            (
                                getallpublicpagecontent.data.data.map((item, index) => {
                                    return (
                                        <AboutBanner key={index} item= {item} />
                                    )

                                }))  : null                       
                    }

                </Row>

                <Link to='/contact'>
                    <Button style={{backgroundColor: '#738063'}} className="primbtn mt-2 ms-auto d-block mb-4">Get Quotation</Button>
                </Link>
            </div>
        </div>
    )
}

export default QuotationText
