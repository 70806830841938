import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetGalleryItemDetailsAction, UpdateGalleryItemAction } from '../../Redux/Actions/GalleryActions';
import notify from './../../CustomHooks/useNotification';


const EditGalleryItemHook = (GalID) => {

    const dispatch = useDispatch();

    const [loadinggalUpdate, setloadinggalUpdate] = useState(null)


    const [GalleryName, setGalleryName] = useState('')
    const [GallerySubTitle, setGallerySubTitle] = useState('')
    const [GalleryDesc, setGalleryDesc] = useState('')
    const [GalleryIsMain, setGalleryIsMain] = useState(false)
    const [GalleryIsActive, setGalleryIsActive] = useState(false)
    const [GalleryVideoLink, setGalleryVideoLink] = useState('')

    let getonegallery = useSelector(state => state.GalleryRed.getonegalleryres)
    let updategalleryitem = useSelector(state => state.GalleryRed.updategalleryitemres)

    /// Handle Get Details of Gallery Item Action With ID Parameter  
    useEffect(() => {
        const run = async () => {
            await dispatch(GetGalleryItemDetailsAction({
                "Id": GalID
            }))
        }
        run()
    }, [])

    //// Assign Name To Text Box Value 
    useEffect(() => {
        const run = () => {
            if (getonegallery) {
                if (getonegallery.data) {
                    setGalleryName(getonegallery.data.data.title)
                    setGallerySubTitle(getonegallery.data.data.subTitle)
                    setGalleryDesc(getonegallery.data.data.description)
                    setGalleryVideoLink(getonegallery.data.data.videoLink)
                    setGalleryIsActive(getonegallery.data.data.isActive)
                    setGalleryIsMain(getonegallery.data.data.isMain)
                }
            }
        }
        run()
    }, [getonegallery])


    const ChangeGalleryTitle = (e) => {
        setGalleryName(e.target.value)
    }


    const ChangeGallerySubTitle = (e) => {
        setGallerySubTitle(e.target.value)
    }


    const ChangeGalleryDesc = (e) => {
        setGalleryDesc(e.target.value)
    }


    const ChangeGalleryMainOrNot = (e) => {
        setGalleryIsMain(!GalleryIsMain)
    }


    const ChangeActive = (e) => {
        setGalleryIsActive(!GalleryIsActive)
    }

    const ChangeGalleryVideoLink = (e) => {
        setGalleryVideoLink(e.target.value)
    }


    /// Clicking The Button & Save Item To Data Base 
    const UpdateGalleryItem = async (e) => {

        e.preventDefault();
        if (GalleryName === '' || GallerySubTitle === '' || GalleryDesc === '') {
            notify('Please Enter Valid Input Values', 'warning')
            return;
        }
        setloadinggalUpdate(true)
        await dispatch(UpdateGalleryItemAction({
            "Title": GalleryName,
            "SubTitle": GallerySubTitle,
            "Description": GalleryDesc,
            "Id": GalID,
            "IsMain": GalleryIsMain,
            "VideoLink": GalleryVideoLink,
            "IsActive": GalleryIsActive
        }))
        setloadinggalUpdate(false)
    }

    //// On Finishing Edit Gallery Item
    useEffect(() => {
        const Run = () => {
            if (loadinggalUpdate === false) {
                //console.log(updategalleryitem);
                if (updategalleryitem && updategalleryitem.status === 200) {
                    setGalleryName('')
                    setGallerySubTitle('')
                    setGalleryDesc('')
                    setGalleryVideoLink('')
                    setGalleryIsMain(false)
                    setGalleryIsActive(false)
                    notify('Updated Successfully', 'success')
                    setTimeout(() => {
                        window.location.pathname = "/admin/gallery"
                    }, 1000);
                }
                else {
                    notify('Please Login Again To Complete', 'error')
                }
            }
        }

        Run()

    }, [loadinggalUpdate])


    return [getonegallery, GalleryName, ChangeGalleryTitle,
        GallerySubTitle, ChangeGallerySubTitle, GalleryDesc, ChangeGalleryDesc, GalleryVideoLink,
        ChangeGalleryVideoLink, GalleryIsActive, ChangeActive, GalleryIsMain, ChangeGalleryMainOrNot,
        UpdateGalleryItem , loadinggalUpdate]
}


export default EditGalleryItemHook
