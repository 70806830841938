const RightArrow = (onClick) => {
    return (
        <div 
        onClick= {onClick}        
        style=
        {{
            color : "#000",
            backgroundColor: '#ccc',
            width: '35px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px 2px',
            borderRadius: '3px',
            top: '50%',
            rigth: '10px',
            position: 'absolute',
            transform: 'translateY(-50%)',
            zIndex: '1000',
            cursor: 'pointer'
        }}>
            <i className='fas fa-angle-right'></i>
        </div>
    )
}

export default RightArrow
